export default {
  eul_document: "EUL Document",
  total_finish: "Total Finish",
  fullfill_data: "Fullfill data to finance needs",
  applicant_information: "Applicant Information",
  occupation_information: "Occupation Information",
  guarantor1_information: "Guarantor 1 Information",
  guarantor2_information: "Guarantor 2 Information",
  upload_document: "Upload Document",
  applicant_title: "Applicant Information",
  need_to_update: "Need to update",
  customer_name: "Applicant Name",
  nrc: "NRC Number",
  father_name: "Father Name",
  phone_number: "Phone Number",
  other_phone_number: "Other Phone Number",
  email: "Email",
  date_of_birth: "Date of Birth",
  nationality: "Nationality",
  Myanmar: "Myanmar",
  Other: "Other",
  gender: "Gender",
  Male: "Male",
  Female: "Female",
  marital_status: "Marital Status",
  Single: "Single",
  Widowed: "Windowed",
  Married: "Married",
  Divorced: "Divorced",
  bank_account: "Do you have bank account?",
  Yes: "Yes",
  No: "No",
  permanent_address: "Permanent Address Information",
  region: "Region",
  city: "City",
  township: "Township",
  ward_or_village: "Ward or Village",
  Ward: "Ward",
  Village: "Village",
  street_address: "Street Address",
  building_no: "Building No",
  floor: "Floor",
  type_of_residence: "Type of Residence",
  Owner: "Owner",
  Rental: "Rental",
  Parental: "Parental",
  Hostel: "Hostel",
  living_with: "Living With",
  Parent: "Parent",
  Offspring: "Offspring",
  Relative: "Relative",
  Spouse: "Spouse",
  Alone: "Alone",
  Friend: "Friend",
  year_of_stay: "Years of Stay",
  year: "Years",
  month: "Months",
  emergency_contact: "Emergency Contact",
  emergency_name: "Name",
  address_of_contact: "Address of contact Person",
  relationship_with_applicant: "Relationship with applicant",
  Sibling: "Sibling",
  back: "Back",
  next: "Next",
  submit: "Submit",
  done: "Done",
  apply: "Apply",
  save_apply_information: "Save Apply Information",
  confirmation: "Please wait for confirmation!",
  save_information: "Save Information",
  successfully_saved: "Your Information is successfully saved!",
  want_exit: "Are you sure to exit?",
  yes: "Yes",
  no: "No",
  is_required: "is required",
  correct_phone_number: "Please add correct phone number",
  no_results: "No results",
  greater_than: "Month is not greater than 11",
  correct_month: "Please add correct Month!",
  correct_salary_date: "Please add correct Salary Date!",
  correct_email: "Please add correct email!",
  correct_nrc: "Please add correct NRC number!",
  enter_nrc: "Please enter NRC Number",
  enter_validate_nrc: "Please enter validate NRC Number",
  occupation_title: "Occupation Information",
  occupation_company_information: "Occupation Company Informations",
  occupation_company_address: "Occupation Company Address Informations",
  company_phone: "Company Phone No",
  hr_name: "Company HR Name",
  hr_phone: "HR Phone Number",
  occupation_personal_information: "Occupation Personal Informations",
  position: "Position",
  department: "Department",
  department_name: "Department Name",
  year_of_service: "Years Of Services",
  monthly_basic_income: "Monthly Basic Salary",
  additional_income: "Additional Income",
  total_income: "Total Income",
  salary_date: "Salary Date",
  guarantor1_title: "Guarantor (Superior) Information",
  guarantor_personal_information: "Guarantor Personal Information",
  relationship_with_customer: "Relationship with Customer",
  guarantor_name: "Guarantor Name",
  guarantor_permanent_address: "Guarantor Permanent Address",
  guarantor_current_address: "Guarantor Current Address",
  guarantor_company_information: "Guarantor Company Informations",
  guarantor_company_address: "Guarantor Company Address",
  guarantor2_title: "Guarantor Colleague Information",
  company_name: "Company Name",
  nrc_front: "Original NRC Front",
  nrc_back: "Original NRC Back",
  census_front: "Original Census Front",
  census_back: "Original Census Back",
  employee_card: "Original Employee ID Card",
  selfie_photo: "Selfie Photo",
  electricity_bill: "Electricity Bill or City Council Bill",
  ward_recommendation_letter: "Ward Recommendation Letter",
  salary_slip: "Salary Slip for 3 Months/ Income statement ",
  company_recommendation_letter: "HR or Company Recommendation",
  loan_amount: "Loan Amount",
  tenor: "Tenor",
  reason: "Reason to use requested Loan",
  accept_teams_and_conditions: `I accept <u class="text-blue">terms and conditions</u> of WinFinance`,
  experience_salary_position_must_be_included:
    "Experience, Salary, Position must be included",
  recent_bill_as_proof_address: "Recent bill as proof address ",
  electricity_bill_or_recent_bill_or_country_council_bill:
    "Electricity bill or Recent Bill or Country Council Bill",
};
